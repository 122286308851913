/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
export const DEFAULT_TYPES = {
    string: '',
    object: {},
    array: [],
    number: 0,
    boolean: false,
    null: null,
};
