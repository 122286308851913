var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ToolBarSelectButton", {
    attrs: { "data-cy": "tool-bar-dropdown" },
    on: { focus: _vm.onFocus },
    scopedSlots: _vm._u([
      {
        key: "input",
        fn: function() {
          return [
            _c("UserFabAvatar", {
              attrs: {
                "avatar-id": _vm.avatarId,
                "user-id": _vm.user.id,
                name: _vm.user.firstName
              }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "title",
              domProps: {
                textContent: _vm._s(
                  _vm.capitalizedUserFirstName || _vm.capitalizedUserLastName
                )
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "icon-wrapper" },
              [
                _c("IconArrowDropdown", {
                  attrs: {
                    "fill-color": _vm.whiteColor,
                    state: _vm.arrowIconState
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "dropdown",
        fn: function() {
          return [_c("ToolBarUserSelectContent")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }