var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tool-bar-user-select-content-header" },
    [
      _c("UserFabAvatar", {
        attrs: {
          "avatar-id": _vm.avatarId,
          "user-id": _vm.user.id,
          name: _vm.initials,
          size: _vm.largeSize
        }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "tool-bar-user-select-content-header__initials",
        domProps: { textContent: _vm._s(_vm.initials) }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "tool-bar-user-select-content-header__email",
        domProps: { textContent: _vm._s(_vm.user.email) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }