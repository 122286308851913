import { render, staticRenderFns } from "./ToolBarUserButton.vue?vue&type=template&id=15691e08&scoped=true&"
import script from "./ToolBarUserButton.vue?vue&type=script&lang=js&"
export * from "./ToolBarUserButton.vue?vue&type=script&lang=js&"
import style0 from "./ToolBarUserButton.vue?vue&type=style&index=0&id=15691e08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15691e08",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ergonode/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15691e08')) {
      api.createRecord('15691e08', component.options)
    } else {
      api.reload('15691e08', component.options)
    }
    module.hot.accept("./ToolBarUserButton.vue?vue&type=template&id=15691e08&scoped=true&", function () {
      api.rerender('15691e08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/core/src/components/ToolBar/ToolBarUserButton.vue"
export default component.exports