var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.avatarClasses },
    [
      _vm.avatarId
        ? _c("LazyImage", {
            attrs: {
              value: _vm.avatarId,
              href: _vm.avatarPath,
              "use-cache": false,
              fab: ""
            }
          })
        : _c("span", { domProps: { textContent: _vm._s(_vm.avatarInitial) } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }