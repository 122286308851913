var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tool-bar-user-select-content",
      attrs: { "data-cy": "tool-bar-content" }
    },
    [
      _c("ToolBarUserSelectContentHeader"),
      _vm._v(" "),
      _c("Divider"),
      _vm._v(" "),
      _vm._l(_vm.navigationBarUserMenu, function(item, index) {
        return _c("MenuList", {
          key: index,
          attrs: { title: item.title, menu: item.menu }
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("Button", {
            attrs: {
              "data-cy": "logout-button",
              theme: _vm.secondaryTheme,
              title: _vm.$t(
                "@Core.core.components.ToolBarUserSelectContent.logout"
              )
            },
            nativeOn: {
              click: function($event) {
                return _vm.onLogout.apply(null, arguments)
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }