/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
export default () => ({
    objectId: null,
    comments: [],
    count: 0,
    currentPage: 1,
});
