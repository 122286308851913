var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "App",
    [
      _c("ToolBar", {
        style: _vm.navigationBarPosition,
        scopedSlots: _vm._u([
          {
            key: "breadcrumbs",
            fn: function() {
              return _vm._l(_vm.breadcrumbs, function(breadcrumb, index) {
                return _c("ToolBarBreadcrumb", {
                  key: index,
                  attrs: { breadcrumb: breadcrumb }
                })
              })
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c("ToolBarUserButton"),
                _vm._v(" "),
                _vm._l(_vm.extendedToolBar, function(component, index) {
                  return [
                    _c(
                      component.component,
                      _vm._b(
                        { key: index, tag: "Component" },
                        "Component",
                        component.props,
                        false
                      )
                    )
                  ]
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("NavigationSideBar", {
        on: { expand: _vm.onExpandNavigationSideBar }
      }),
      _vm._v(" "),
      _c(
        "AppMain",
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm._l(_vm.extendedAppMain, function(component, index) {
            return [
              _c(
                component.component,
                _vm._b(
                  { key: index, tag: "Component" },
                  "Component",
                  component.props,
                  false
                )
              )
            ]
          }),
          _vm._v(" "),
          _c("FlashMessages")
        ],
        2
      ),
      _vm._v(" "),
      _vm._l(_vm.modals, function(modal, index) {
        return _c(
          modal.component,
          _vm._b(
            {
              key: index,
              tag: "Component",
              attrs: { index: index },
              on: { close: _vm.onCloseModal }
            },
            "Component",
            modal.props,
            false
          )
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }