var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: { d: _vm.symbolDrawingPath, fill: _vm.symbolColor }
      }),
      _vm._v(" "),
      _vm.fullLogo
        ? _c("path", { attrs: { d: _vm.nameDrawingPath, fill: _vm.nameColor } })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }